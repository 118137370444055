import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sales"
    }}>{`Sales`}</h1>
    <p>{`Welcome to the Sales API.`}</p>
    <p>{`Sales API is a collection of all the APIs needed to perform a sale ranging from the creation of Orders and receiving Payments to sending Receipts to the customer. In this section we will document the different available APIs and guide you through your first sale.`}</p>
    <h2 {...{
      "id": "services"
    }}>{`Services`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Service`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "pages-sales-order-api"
            }}>{`Order`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Create, update, cancel, reset and search for orders.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-reserve-offers-api"
            }}>{`Reserve offer`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Converts offers to order lines, adds them to the order and performs necessary seat reservations.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-seating-arrangement-api"
            }}>{`Seating Arrangement`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Handles mapping between seat reservations and order lines.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-payment-api"
            }}>{`Payment`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Search, create, update and execute payments and credits.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-ticket-distribution-api"
            }}>{`Ticket Distribution`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Manage and distribute new tickets to ticketing systems.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-receipt-api"
            }}>{`Receipt`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Creates and sends receipts for orders.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-reserve-offers-api#change-options"
            }}>{`Change Options`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Finds all changes allowed to perform on an order.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-refund-api"
            }}>{`Refund`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Finds all refund-options and conducts refund of an order.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/pages-sales-third-party-product-api"
            }}>{`Third Party Product`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Retreive products outside of the standard catalog.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`All endpoints in the Sales API requires an authentiation header. The authentication header must be an Entur-issued OAuth2 bearer token. In order to Issue a valid token you must have a client_id and client_secret. (TODO)`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --request POST \\
  --url 'https://<type_of_token>.<environment>.entur.org/oauth/token' \\
  --header 'content-type: application/json' \\
  --data '{"grant_type":"client_credentials","client_id": "<clientid>","client_secret": "<clientsecret>","audience": "https://api.<envirnoment>.entur.io"}
`}</code></pre>
    <h2 {...{
      "id": "headers"
    }}>{`Headers`}</h2>
    <p>{`Most endpoints also requires a header describing which point-of-sale (POS) the request originates from. This value is represented by the header Entur-POS. The Point of Sale header indicates where the product was purchased. This is a convenience header for the client, and it is up to the client using the API to decide the content for it.
All requests will also have a correlation id which is pertained with a request as it propagates through the system. This value will show which request is being processed as it flows through the system. This value is automatically created and maintained by the internal logging library, and should not require any further actions from client developers.
Sales and payment requires a header describing which distribution channel id (DCI) the request originates from. Distribution Channels controls which organisations can sell, refund or inform on available products or salepoints.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Header key`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Example value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Point of Sale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entur-POS`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Entur-APP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Correlation Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X-Correlation-Id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`7c9786df-cadf-4a70-9487-541607ade1b4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bearer xxxxxxx.yyyyyyyy.zzzzzzzzz`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Distribution Channel Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entur-Distribution-Channel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ENT:DistributionChannel:App`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "versioning"
    }}>{`Versioning`}</h2>
    <p>{`The Entur Sales API follows a unified versioning scheme for the entire service portfolio, meaning that all services within the API should expose the same endpoint versions.
Bumping this global version number indicates a breaking API change, and thus should happen infrequently.`}</p>
    <h3 {...{
      "id": "format"
    }}>{`Format:`}</h3>
    <p>{`The API version consists of a "v" prefix, followed by the API version number, e. g. "v1", "v2", etc.`}</p>
    <h3 {...{
      "id": "api-consistency"
    }}>{`API consistency:`}</h3>
    <p>{`All endpoints for a specific API version should guarantee API consistency – a client should be confident that the API will not change, as long as the version remains constant.
For development purposes, endpoints subject to changes should maintain a beta version number throughout development, i. e. "/v1beta/...".`}</p>
    <h2 {...{
      "id": "pagination"
    }}>{`Pagination`}</h2>
    <p>{`Some of the endpoints for the API will potentially return large arrays of data, which could be taxing for the client. For this reason, the client must use pagination to limit the number or results returned from the API.
Specifying current page and page size`}</p>
    <p>{`To specify which page to be returned from the API, use the page query parameter in the URL. There is a default page size set for the relevant services, but this size can be overridden by specifying the perPage query parameter.
In addition to the items for the current page, the response will contain the total item count (totalItems) and the total number of pages (totalPages).`}</p>
    <p>{`This is an example of a query to `}<inlineCode parentName="p">{`/sales/v1/payments?page=1&perPage=4`}</inlineCode>{`:`}</p>
    <ExpandablePanel title="Example query" mdxType="ExpandablePanel">
    <pre>
        {`{
    "items": [
        {
            "paymentId": 2396,
            "totalAmount": "1446.00",
            "currency": "NOK",
            "orderId": "ABCDEFGH",
            "orderVersion": 1,
            "transactionHistory": [],
            "links": []
        },
        {
            "paymentId": 2433,
            "totalAmount": "152.00",
            "currency": "NOK",
            "orderId": "HIJKLMNO",
            "orderVersion": 1,
            "transactionHistory": [],
            "links": []
        },
        {
            "paymentId": 2434,
            "totalAmount": "152.00",
            "currency": "NOK",
            "orderId": "PQRSTUVW",
            "orderVersion": 1,
            "transactionHistory": [
                {
                    "transactionId": 2167,
                    "currency": "NOK",
                    "paymentType": "VISA",
                    "cardNumber": "XXXXXX******XXXX",
                    "summary": {
                        "remainingAmountToCapture": "0.00",
                        "capturedAmount": "152.00",
                        "remainingAmountToCredit": "152.00",
                        "creditedAmount": "0.00"
                    },
                    "amount": "152.00",
                    "status": "CAPTURED",
                    "links": []
                }
            ],
            "links": []
        },
        {
            "paymentId": 2438,
            "totalAmount": "152.00",
            "currency": "NOK",
            "orderId": "XYZABCDE",
            "orderVersion": 1,
            "transactionHistory": [
                {
                    "transactionId": 2171,
                    "currency": "NOK",
                    "paymentType": "MASTERCARD",
                    "cardNumber": "XXXXXX******XXXX",
                    "summary": {
                        "remainingAmountToCapture": "0.00",
                        "capturedAmount": "152.00",
                        "remainingAmountToCredit": "152.00",
                        "creditedAmount": "0.00"
                    },
                    "amount": "152.00",
                    "status": "CAPTURED",
                    "links": []
                }
            ],
            "links": []
        }
    ],
    "totalItems": 16,
    "totalPages": 4
}
`}
    </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "query-filters"
    }}>{`Query filters`}</h2>
    <p>{`Paged endpoints can also contain a form of filtering queries. These are query parameters which can be appended multiple times to build up a more complex filter.`}</p>
    <h3 {...{
      "id": "filter-operators"
    }}>{`Filter operators`}</h3>
    <p>{`eq - equal to
ne - not equal
gt - greater than
gte - greater than or equal
lt - lower than
lte - lower than or equal`}</p>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <p><inlineCode parentName="p">{`/orders?totalAmount=gte:1000.00&organisationId=eq:1`}</inlineCode>{` would return all orders where total amount is greater than or equal to 1000.00 and has organisationId equal to 1.`}</p>
    <h2 {...{
      "id": "currency"
    }}>{`Currency`}</h2>
    <p>{`The Entur Sales API has a standardised way of representing value of products. Firstly, a currency code must always be specified for a given product (e. g. "NOK", "USD", etc). Secondly, the amount for the given currency is represented as a numeric string with two decimal points (e. g. "100.00").`}</p>
    <p>{`The example below shows how an order costing NOK 1446.00 is represented in the API. Note that many fields has been omitted from the order structure for better readability.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "id": "ABCDEFGH",
    "version": 1,
    "status": "CONFIRMED",
    "totalAmount": "1446.00",
    "totalTaxAmount": "154.93",
    "balance": "0.00",
    "currency": "NOK",
    "paymentRefs": [
        {
            "orderVersion": 1,
            "paymentId": 6406,
            "amount": "1446.00",
            "currency": "NOK",
            "completedAt": "2018-09-03T12:47:18Z"
        }
    ],
    "travellers": [],
    "reservations": [],
    "organisationId": 1,
    "createdAt": "2018-09-03T12:44:27Z"
}
`}</code></pre>
    <h2 {...{
      "id": "dates"
    }}>{`Dates`}</h2>
    <p>{`All dates returned from the Entur Sales API follows the ISO 8601 standard, i. e. `}<inlineCode parentName="p">{`"YYYY-MM-DDTHH:mm:SSZ"`}</inlineCode>{`.`}</p>
    <p>{`Note that time is always represented in UTC, so the developer needs to convert it to the correct local time before presented to the end user.
`}<inlineCode parentName="p">{`2018-12-24T18:00:00Z`}</inlineCode>{` is an example of a valid timestamp returned from the API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      